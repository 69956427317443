import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import img from "../assets/img/fondo3.png";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { host } from "../variables/connection.js";
import resultadosHelper from "../variables/resultados.js";
import tokensHelpers from "../variables/tokens.js";
import img2 from "assets/img/corriendo.gif";
import SprintRoadmap from "../components/Sprints/sprintroadmap.jsx";

const resultados = resultadosHelper.tablaResultados;
const decodeToken = tokensHelpers.decodeToken;

const tableContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
};

const tableContainerStyle2 = {
  display: "flex",
  justifyContent: "center",
  width: "100%",
};

const tableStyle = {
  width: "30%", // Ancho ajustable
  borderCollapse: "collapse",
  margin: "20px 0",
};

const thStyle = {
  backgroundColor: "#f2f2f2",
  border: "1px solid #ddd",
  padding: "8px",
  textAlign: "center",
  color: "black",
};

const tdStyle = {
  border: "1px solid #ddd",
  padding: "8px",
  textAlign: "left",
  color: "white",
};

function BarraNombreSprint() {
  const visible = 1;

  const mensajeSprint = "Sprint Roadmap";

  const colorMapping = {
    Roadmap: '#e49d13',
  };

  const colorFondoBarra = colorMapping[mensajeSprint] || 'blue';

  // timer
  //useEffect(() => {
  //	const timerBarraNombre = setTimeout(() => {
  //		setVisible(false);
  //	}, 3000);
  //	return () => clearTimeout(timerBarraNombre);
  //}, []);

  const barraEstilo = {
    backgroundColor: colorFondoBarra,
    color: 'white',
    padding: '10px',
    marginTop: '80px',
    position: 'absolute',
    top: '50%',
    left: '0',
    transform: 'translateY(-50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    opacity: visible ? 1 : 0,
    transition: 'opacity 1s ease',
    zIndex: 1000,
    marginBottom: '50px', // Agregar margen inferior
  };

  const estiloTexto = {
    fontSize: '50px',
    marginRight: '20px'
  };

  const imagenGifEstilo = {
    width: '100px',
    height: '100px'
  };

  return (
    <div style={barraEstilo}>
      <span style={estiloTexto}>{mensajeSprint}</span>
      <img src={img2} alt="GIF" style={imagenGifEstilo} />
    </div>
  )

}

function Roadmap(props) {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(true);

  const [userType, setUserType] = useState([]);
  const [partidas, setPartidas] = useState([]);
  const [selectedPartida, setSelectedPartida] = useState();
  const [nombreEquipo, setNombreEquipo] = useState("");
  const [equipos, setEquipos] = useState([]);
  const [selectedEquipo, setSelectedEquipo] = useState();

  const [datosAñoActual, setdatosAñoActual] = useState([]);
  const [datosAñoActualE, setdatosAñoActualE] = useState([]);
  const [TablaMetricas, setTablaMetricas] = useState([]);
  const [tableData, settableData] = useState([]);
  const [rtdoNeto, setRtdoNeto] = useState(null); // Variable para guardar el RTDO NETO
  const [nombreSprint, setNombreSprint] = useState("Roadmap");

  useEffect(() => {
    setearConfDesdeToken(); // Para que se ejecute al montar el componente
  }, []); // Se ejecutará solo una vez al montar el componente gracias a la dependencia vacía []

  function setearConfDesdeToken() {
    // recibir token de local storage
    const token = localStorage.getItem("token");

    if (token) {
      console.log("Token found:", token);
      const claims = decodeToken(token);
      console.log("Token claims:", claims);

      // armar idioma
      if (claims.idioma) {
        i18n.changeLanguage(claims.idioma);
      }

      // configuración de juego
      if (claims.role === "Player" || claims.role === "Captain") {
        setUserType("Player");
        setSelectedPartida(claims.partida);
        setSelectedEquipo(claims.equipo);

        // traerResultados(claims.partida, claims.equipo)
      } else if (claims.role === "Admin") {
        setUserType("Admin");
      } else {
        console.error("y vos quien sos ???");
      }
    }
  }

  const fetchPartidas = async () => {
    try {
      const response = await fetch(`https://${host}/api/partidas`);
      if (!response.ok) {
        throw new Error("Respuesta del servidor no es OK");
      }
      const data = await response.json();
      if (Array.isArray(data.data) && data.data.length) {
        setPartidas(data.data);
        setSelectedPartida(data.data[0].id); // Corregido para seleccionar el primer ID
      } else {
        console.error("Formato de datos no esperado:", data);
      }
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
  };

  const fetchEquiposPorPartida = async (partidaId) => {
    try {
      const response = await fetch(`https://${host}/juego/${partidaId}`);
      if (!response.ok) {
        throw new Error("Respuesta del servidor no es OK");
      }
      const data = await response.json();
      if (Array.isArray(data.data)) {
        setEquipos(data.data);
      } else {
        setEquipos([]);
        console.warn(data.message);
      }
    } catch (error) {
      setEquipos([]); // En caso de un error, resetea los equipos.
      console.error("Error al obtener los equipos (o no los pediste todavía):", error);
    }
  };

  useEffect(() => {
    fetchPartidas();
  }, []);

  // Este useEffect se encargará de cargar los equipos cuando cambie la partida seleccionada
  useEffect(() => {
    if (selectedPartida) {
      fetchEquiposPorPartida(selectedPartida);
    }
  }, [selectedPartida]);

  // Use useEffect to fetch resultados data and set loading state
  useEffect(() => {
    resultados(selectedPartida, selectedEquipo)
      .then((result) => {
        console.log("Resultados traídos exitosamente:", result);
        var datosResultados = result;
        console.log(JSON.stringify(datosResultados));
        setdatosAñoActual(result.datosAñoActual);
        setdatosAñoActualE(result.datosAñoActualE);
        setTablaMetricas(result.TablaMetricas);
        settableData(result.tableData);
        console.log("Datos Año Actual:", result.datosAñoActual);
        console.log("Datos Año Actual Egresos:", result.datosAñoActualE);
        // Extraer el dato de RTDO NETO
        // Capturar el dato en la posición 20
        const rtdoNetoItem = result.datosAñoActualE[20];
        if (rtdoNetoItem) {
          setRtdoNeto(rtdoNetoItem.cantidad);
          console.log(`RTDO NETO encontrado: ${rtdoNetoItem.cantidad}`);
        } else {
          console.error("RTDO NETO no encontrado en la posición 20.");
        }

        setLoading(false); // Set loading to false once data is available
      })
      .catch((error) => {
        console.error("Error trayendo resultados:", error);
        setLoading(true); // Set loading to false in case of an error
      });
  }, [selectedPartida, selectedEquipo]);

  return (
    <>
      <div
        className="content"
        style={{
          backgroundImage: `url(${img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          width: "100vw",
        }}
      >
        {/* ESPERAR A QUE CARGUEN LOS DATOS ANTES DE RENDER */}
        {loading ? (
          <p>{t("common.cargandoResultados")}...</p>
        ) : (
          // Render your tables and charts when loading is false
          <>
            <Row>
              <Col xs="12">
                <Card style={{ backgroundColor: "#003366" }}>
                  <BarraNombreSprint />
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Card style={{ backgroundColor: "#003366" }}>
                  <CardBody>

                    <div style={{ ...tableContainerStyle, marginTop: '100px' }}>
                      {rtdoNeto && (
                        <p style={{ color: "white", fontWeight: "bold", fontSize: "24px" }}>
                          budget: {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(
                            parseFloat(rtdoNeto.replace(/[^0-9,-]+/g, '').replace('.', '').replace(',', '.')) * 0.4
                          )}
                        </p>
                      )}
                    </div>

                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Card style={{ backgroundColor: '#003366' }}>
                  <CardBody>
                    <SprintRoadmap />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Card style={{ backgroundColor: "#003366" }}>
                  <CardBody>
                    <div style={tableContainerStyle}>
                      <table style={tableStyle}>
                        <thead>
                          <tr>
                            <th colSpan="2" style={thStyle}>
                              {t("estadoRes.currentYear")}
                            </th>
                          </tr>
                          <tr>
                            <th colSpan="2" style={thStyle}>
                              {t("estadoRes.ingresos")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {datosAñoActual.map((item, index) => (
                            <tr key={index}>
                              <td style={tdStyle}>
                                {index === 1 ? (
                                  <strong>{item.concepto}</strong>
                                ) : (
                                  item.concepto
                                )}
                              </td>
                              <td style={tdStyle}>{item.cantidad}</td>
                            </tr>
                          ))}
                        </tbody>
                        <thead>
                          <tr>
                            <th colSpan="2" style={thStyle}>
                              {t("estadoRes.egresos")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {datosAñoActualE.map((item, index) => (
                            <tr key={index}>
                              <td style={tdStyle}>
                                {index === 0 ||
                                  index === 12 ||
                                  index === 18 ||
                                  index === 19 ||
                                  index === 20 ||
                                  index === 21 ? (
                                  <strong>{item.concepto}</strong>
                                ) : (
                                  item.concepto
                                )}
                              </td>
                              <td style={tdStyle}>{item.cantidad}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div >
    </>
  );
}

export default Roadmap;