import { useState, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate } from 'react-router-dom';

import carta1 from './cartas/1.png';
import carta2 from './cartas/2.png';
import carta3 from './cartas/3.png';
import carta4 from './cartas/4.png';
import carta5 from './cartas/5.png';
import carta6 from './cartas/6.png';
import carta7 from './cartas/7.png';
import carta8 from './cartas/8.png';
import carta9 from './cartas/9.png';
import carta10 from './cartas/10.png';
import carta11 from './cartas/11.png';
import carta12 from './cartas/12.png';
import carta13 from './cartas/13.png';
import carta14 from './cartas/14.png';
import carta15 from './cartas/15.png';
import carta16 from './cartas/16.png';
import carta17 from './cartas/17.png';

const phases = ['Fase 1', 'Fase 2', 'Fase 3'];

const categories = [
  {
    name: 'Personas',
    cards: [
      { id: 1, name: 'Dueño del producto', cost: 15000, value: 80, background: carta1 },
      { id: 2, name: 'Experto en la materia', cost: 12000, value: 70, background: carta2 },
      { id: 3, name: 'Analista de negocios', cost: 13000, value: 75, background: carta3 },
      { id: 4, name: 'Ingeniero en software', cost: 10000, value: 60, background: carta4 },
      { id: 5, name: 'Ingeniero en datos', cost: 10000, value: 60, background: carta5 },
      { id: 6, name: 'Científico de datos', cost: 11000, value: 65, background: carta6 },
      { id: 7, name: 'Ingeniero en ML', cost: 10000, value: 70, background: carta7 },
      { id: 8, name: 'Gestor de Ecosistema', cost: 10000, value: 70, background: carta8 },
      { id: 9, name: 'Experto Financiero', cost: 10000, value: 70, background: carta9 },
      //  { id: 10, name: 'Maestro Scrum', cost: 50000, value: 50, background: carta10 },
      //  { id: 11, name: 'Coach Ágil', cost: 50000, value: 50, background: carta11 },
      //  { id: 12, name: 'Experto en Marketing', cost: 100000, value: 60, background: exampleImage },
      //  { id: 13, name: 'Experto experiencia de cliente', cost: 80000, value: 60, background: exampleImage },
      //  { id: 14, name: 'Gerente de proyectos TI', cost: 100000, value: 70, background: exampleImage },
      //  { id: 15, name: 'Especialista en seguridad informática', cost: 100000, value: 70, background: exampleImage },
      //  { id: 16, name: 'Director de tecnología (CTO)', cost: 100000, value: 75, background: exampleImage },
      //  { id: 17, name: 'Project manager officer (PMO)', cost: 90000, value: 65, background: exampleImage },
    ],
    multipliers: [3, 2, 1],
  },
  {
    name: 'Tecnología',
    cards: [
      { id: 18, name: 'Data-based solutions', cost: 15000, value: 60, background: carta10 },
      { id: 19, name: 'On-Demand Services', cost: 15000, value: 60, background: carta11 },
      { id: 20, name: 'People network base solutions', cost: 15000, value: 60, background: carta12 },
      { id: 21, name: 'Integrated Solutions', cost: 15000, value: 60, background: carta13 },
      { id: 22, name: 'Smart Solutions', cost: 15000, value: 60, background: carta14 },
      //  { id: 23, name: 'Accelerate decision', cost: 15000, value: 60, background: exampleImage },
      //  { id: 24, name: 'Digital Customer Relationship', cost: 15000, value: 60, background: exampleImage },
      //  { id: 25, name: 'People network base', cost: 15000, value: 60, background: exampleImage },
      //  { id: 26, name: 'Business Network', cost: 15000, value: 60, background: exampleImage },
      //  { id: 27, name: 'Door opening Devices', cost: 15000, value: 60, background: exampleImage },
      //  { id: 28, name: 'Customized Channels', cost: 15000, value: 60, background: exampleImage },
      //  { id: 29, name: 'Ubiquitous Access', cost: 15000, value: 60, background: exampleImage },
      //  { id: 30, name: 'Community Channels', cost: 15000, value: 60, background: exampleImage },
      //  { id: 31, name: 'Platforms Channels', cost: 15000, value: 60, background: exampleImage },
      //  { id: 32, name: 'Smart Thing Channels', cost: 15000, value: 60, background: exampleImage },
      //  { id: 33, name: 'Micro segments', cost: 15000, value: 60, background: exampleImage },
      //  { id: 34, name: 'Global Reach', cost: 15000, value: 60, background: exampleImage },
      //  { id: 35, name: 'Digital Consumers', cost: 15000, value: 60, background: exampleImage },
      //  { id: 36, name: 'Global reach by Business network', cost: 15000, value: 60, background: exampleImage },
      //  { id: 37, name: 'Extended reach', cost: 15000, value: 60, background: exampleImage },
      //  { id: 38, name: 'People network', cost: 15000, value: 60, background: exampleImage },
      //  { id: 39, name: 'Business network Customer Side', cost: 15000, value: 60, background: exampleImage },
      //  { id: 40, name: 'Data Monetization', cost: 15000, value: 60, background: exampleImage },
      //  { id: 41, name: 'Digital Service Monetization', cost: 15000, value: 60, background: exampleImage },
      //  { id: 42, name: 'Cloud Infra Monetization', cost: 15000, value: 60, background: exampleImage },
      // { id: 43, name: 'Community Access', cost: 15000, value: 60, background: exampleImage },
      // { id: 44, name: 'Business Network Access', cost: 15000, value: 60, background: exampleImage },
      //  { id: 45, name: 'Smart Things', cost: 15000, value: 60, background: exampleImage },
      //  { id: 46, name: 'Adaptive Pricing', cost: 15000, value: 60, background: exampleImage },
      // { id: 47, name: 'Pay per use', cost: 15000, value: 60, background: exampleImage },
      //  { id: 48, name: 'Group based pricing', cost: 15000, value: 60, background: exampleImage },
      //  { id: 49, name: 'Business to Business', cost: 15000, value: 60, background: exampleImage },
      //  { id: 50, name: 'Data Content', cost: 15000, value: 60, background: exampleImage },
      //  { id: 51, name: 'Cloud Provider', cost: 15000, value: 60, background: exampleImage },
      //  { id: 52, name: 'Community Providers', cost: 15000, value: 60, background: exampleImage },
      // { id: 53, name: 'Business Platform Providers', cost: 15000, value: 60, background: exampleImage },
      //  { id: 54, name: 'Smart Things Partners', cost: 15000, value: 60, background: exampleImage },
      //  { id: 55, name: 'People Networks', cost: 15000, value: 60, background: exampleImage },
      //  { id: 56, name: 'Business Networks', cost: 15000, value: 60, background: exampleImage },
      //  { id: 57, name: 'Data as a Source', cost: 15000, value: 60, background: exampleImage },
    ],
    multipliers: [3, 2, 1],
  },
  {
    name: 'Partners',
    cards: [
      { id: 58, cost: 60000, value: 60, background: carta15 },
      { id: 59, cost: 60000, value: 60, background: carta16 },
      { id: 60, cost: 60000, value: 60, background: carta17 },
    ],
    multipliers: [3, 2, 1],
  },
];

const Card = ({ id, background }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'CARD',
    item: { id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }), [id]);

  return (
    <div
      ref={drag}
      style={{
        width: '100px',
        height: '150px',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        margin: '5px',
        border: '1px solid #000',
        cursor: 'grab',
        opacity: isDragging ? 0.5 : 1,
      }}
    />
  );
};

const DropZone = ({ phase, category, onDrop, children, onRemove, isLocked }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'CARD',
    drop: (item) => {
      if (!children && !isLocked) {
        onDrop(item, phase, category);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }), [phase, category, children, isLocked]);

  return (
    <div
      ref={drop}
      style={{
        width: '100px',
        height: '150px',
        margin: '5px',
        backgroundColor: isOver ? '#e0e0e0' : '#f9f9f9',
        border: '1px dashed #000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={() => !isLocked && children && onRemove(phase, category)}
    >
      {children && (
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundImage: `url(${children.background})`,
            backgroundSize: 'cover',
          }}
        />
      )}
    </div>
  );
};

const RoadmapSprint = () => {
  const [cards, setCards] = useState(categories.flatMap((c) => c.cards));
  const [droppedCards, setDroppedCards] = useState({});
  const [financials, setFinancials] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const savedState = localStorage.getItem('gameState');
    if (savedState) {
      const { droppedCards: savedDroppedCards, financials: savedFinancials } = JSON.parse(savedState);
      setDroppedCards(savedDroppedCards);
      setFinancials(savedFinancials);
      setIsLocked(true); // Bloquear el juego si ya hay un estado guardado
    }
  }, []);

  const handleDrop = (item, phase, category) => {
    if (isLocked) return;

    const card = cards.find((c) => c.id === item.id);
    if (!card || droppedCards[`${category.name}-${phase}`]) return;

    setDroppedCards((prev) => ({
      ...prev,
      [`${category.name}-${phase}`]: card,
    }));

    setCards((prev) => prev.filter((c) => c.id !== card.id));

    const phaseIndex = phases.indexOf(phase);
    const multiplier = category.multipliers[phaseIndex];
    setFinancials((prev) => prev + card.cost * multiplier);
  };

  const handleRemove = (phase, category) => {
    if (isLocked) return;

    const key = `${category.name}-${phase}`;
    const card = droppedCards[key];
    if (!card) return;

    setDroppedCards((prev) => {
      const updated = { ...prev };
      delete updated[key];
      return updated;
    });

    setCards((prev) => [...prev, card]);

    const phaseIndex = phases.indexOf(phase);
    const multiplier = category.multipliers[phaseIndex];
    setFinancials((prev) => prev - card.cost * multiplier);
  };

  const handleSendPlay = () => {
    setIsLocked(true);
    const gameState = { droppedCards, financials };
    localStorage.setItem('gameState', JSON.stringify(gameState));
  };

  const handleGoBack = () => {
    navigate('/CapitanPresencial/Final');
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div style={{ padding: '20px' }}>
        <h1>Roadmap Sprint</h1>

        <div style={{ display: 'flex', marginBottom: '10px', color: 'white' }}>
          <div style={{ width: '100px', textAlign: 'center' }}></div>
          {phases.map((phase) => (
            <div key={phase} style={{ width: '100px', textAlign: 'center' }}>{phase}</div>
          ))}
        </div>

        {categories.map((category) => (
          <div key={category.name} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', color: 'white' }}>
            <div style={{ width: '100px', textAlign: 'center', fontWeight: 'bold' }}>{category.name}</div>
            {phases.map((phase) => (
              <DropZone
                key={`${category.name}-${phase}`}
                phase={phase}
                category={category}
                onDrop={handleDrop}
                onRemove={handleRemove}
                isLocked={isLocked}
              >
                {droppedCards[`${category.name}-${phase}`]}
              </DropZone>
            ))}
          </div>
        ))}

        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: '20px' }}>
          {!isLocked &&
            cards.map((card) => (
              <Card key={card.id} {...card} />
            ))}
        </div>

        <div style={{ marginTop: '20px' }}>
          <h2>Projected budget</h2>
          <p>Total: ${financials.toLocaleString()}</p>
        </div>

        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
          {!isLocked && (
            <button
              onClick={handleSendPlay}
              style={{
                padding: '10px 20px',
                backgroundColor: 'blue',
                color: 'white',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              Enviar Jugada
            </button>
          )}
          <button
            onClick={handleGoBack}
            style={{
              padding: '10px 20px',
              backgroundColor: 'green',
              color: 'white',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            ← Regresar
          </button>
        </div>
      </div>
    </DndProvider>
  );
};

export default RoadmapSprint;
