import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';
import imagen1 from './11.png';
import imagen2 from './22.png';
import imagen3 from './33.png';
import imagen4 from './44.png';
import imagen5 from './55.png';
import imagen6 from './66.png';
import imagen7 from './77.png';
import carta1 from '../../assets/img/11.png';
import carta2 from '../../assets/img/22.png';
import carta3 from '../../assets/img/33.png';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

const sections = [
  'Descubrimiento', 'Investigacion', 'Consideracion',
  'Decision', 'Compra', 'Post Compra', 'Fidelizacion'
];

const initialImages = [
  { id: 1, src: carta1, points: 3 },
  { id: 2, src: carta2, points: 3 },
  { id: 3, src: carta3, points: 2 },
];

const sectionBackgrounds = [
  imagen1, imagen2, imagen3, imagen4, imagen5, imagen6, imagen7
];

const problemPersonData = {
  "Problema 1, Persona 3": [2, 3, 4, 1, 2, 3, 4],
  "Problema 2, Persona 2": [1, 2, 1, 3, 4, 2, 1],
  "Problema 3, Persona 1": [3, 2, 1, 4, 3, 2, 1],
  "Problema 4, Persona 4": [4, 3, 2, 1, 4, 3, 2]
};

const Section = ({ title, backgroundImage, children, onDrop }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'IMAGE',
    drop: (item) => {
      onDrop(item, title);
      return { name: title };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  }), [title]);

  return (
    <div style={{
      flex: '0 0 125px',
      height: '200px',
      padding: '10px',
      margin: '0 5px',
      border: '2px dashed #ccc',
      borderRadius: '8px',
      textAlign: 'center',
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: '100% 100%',
      backgroundPosition: 'center',
      backgroundColor: isOver ? '#f0f8ff' : 'white',
      color: 'black',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start'
    }} ref={drop}>
      <h3 style={{ color: 'black' }}>{title}</h3>
      {children}
    </div>
  );
};

const ImageBox = ({ id, src, points }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'IMAGE',
    item: { id, src, points },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }), [id, src, points]);

  return (
    <div style={{
      margin: '5px',
      backgroundColor: '#87cefa',
      border: '1px solid #4682b4',
      borderRadius: '4px',
      cursor: 'move',
      opacity: isDragging ? 0.5 : 1,
      color: 'black'
    }} ref={drag}>
      <img src={src} alt={`Imagen ${id}`} style={{ width: '100px', height: '133px' }} />
    </div>
  );
};

const Customersprint = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [images] = useState(initialImages);
  const [droppedImages, setDroppedImages] = useState(sections.map(() => []));
  const [baseValues, setBaseValues] = useState([]);
  const [showImprovement, setShowImprovement] = useState(false);

  useEffect(() => {
    // Asignar un problema al azar al cargar el componente
    const randomProblem = Object.keys(problemPersonData)[Math.floor(Math.random() * 4)];
    setSelectedOption(randomProblem);
  }, []);

  useEffect(() => {
    if (selectedOption) {
      setBaseValues(problemPersonData[selectedOption]);
    } else {
      setBaseValues([]);
    }
  }, [selectedOption]);

  const handleDrop = (item, section) => {
    const sectionIndex = sections.indexOf(section);
    if (sectionIndex !== -1) {
      setDroppedImages((prev) => {
        const newDroppedImages = [...prev];
        newDroppedImages[sectionIndex] = [...newDroppedImages[sectionIndex], item];
        return newDroppedImages;
      });
    }
  };

  const modData = sections.map((_, i) => {
    const baseValue = baseValues[i] || 1;
    const additionalPoints = droppedImages[i].reduce((acc, img) => acc + img.points, 0);
    return Math.min(Math.max(baseValue + additionalPoints, 1), 5);
  });

  const lineData = {
    labels: sections,
    datasets: [
      {
        label: 'Mejora',
        data: modData,
        fill: false,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        hidden: !showImprovement
      },
      {
        label: 'Situación inicial',
        data: baseValues,
        fill: false,
        backgroundColor: 'rgba(255,99,132,0.4)',
        borderColor: 'rgba(255,99,132,1)'
      }
    ]
  };

  const lineOptions = {
    scales: {
      y: {
        beginAtZero: false,
        min: 1,
        max: 5,
        ticks: {
          display: false
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.2)'
        }
      },
      x: {
        ticks: {
          color: 'white',
          font: {
            size: 25
          }
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.2)'
        }
      }
    },
    plugins: {
      legend: {
        labels: {
          color: 'white',
          font: {
            size: 25
          }
        }
      }
    }
  };

  const handleSendMove = () => {
    setShowImprovement(true);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px' }}>
        <header style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '20px'
        }}>
          <select
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
            style={{ fontSize: '1rem', padding: '10px' }}
            disabled // Deshabilitado para evitar cambios
          >
            <option value="">Selecciona una opción</option>
            <option value="Problema 1, Persona 3">Problema 1, Persona 3</option>
            <option value="Problema 2, Persona 2">Problema 2, Persona 2</option>
            <option value="Problema 3, Persona 1">Problema 3, Persona 1</option>
            <option value="Problema 4, Persona 4">Problema 4, Persona 4</option>
          </select>
          <button onClick={handleSendMove} style={{ fontSize: '1rem', padding: '10px' }}>
            Enviar jugada
          </button>
        </header>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', flexWrap: 'wrap', color: 'black' }}>
          {sections.map((section, index) => (
            <Section key={section} backgroundImage={sectionBackgrounds[index]} onDrop={(item) => handleDrop(item, section)}>
              {droppedImages[index].map(image => (
                <ImageBox key={image.id} {...image} />
              ))}
            </Section>
          ))}
        </div>
        <div style={{ marginBottom: '20px' }}>
          <h3 style={{ color: 'white' }}>Cartas</h3>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {images.map((image) => (
              <ImageBox key={image.id} {...image} />
            ))}
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <Line data={lineData} options={lineOptions} />
          <div style={{ position: 'absolute', top: '55%', transform: 'translateY(-55%)', paddingLeft: '100px' }}>
            <div style={{ fontSize: '25px', marginBottom: '65px' }}>😃</div>
            <div style={{ fontSize: '25px', marginBottom: '65px' }}>😊</div>
            <div style={{ fontSize: '25px', marginBottom: '65px' }}>😐</div>
            <div style={{ fontSize: '25px', marginBottom: '65px' }}>😞</div>
            <div style={{ fontSize: '25px', marginBottom: '65px' }}>😢</div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default Customersprint;