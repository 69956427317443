import { useState, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import carta1 from './1.png';
import carta2 from './2.png';
import carta3 from './3.png';
import carta4 from './4.png';
import carta5 from './5.png';
import carta6 from './6.png';
import carta7 from './7.png';
import carta8 from './8.png';
import carta9 from './9.png';
import carta10 from './10.png';
import carta11 from './11.png';
import carta12 from './12.png';
import carta13 from './13.png';
import carta14 from './14.png';
import carta15 from './15.png';
import carta16 from './16.png';
import carta17 from './17.png';
import carta18 from './18.png';
import carta19 from './19.png';
import carta20 from './20.png';
import carta21 from './21.png';
import carta22 from './22.png';
import carta23 from './22.png';
import carta24 from './22.png';
import carta25 from './22.png';
import carta26 from './22.png';
import carta27 from './22.png';
import zone1 from './zone1.png';
import zone2 from './zone2.png';
import zone3 from './zone3.png';
import zone4 from './zone4.png';
import zone5 from './zone5.png';
import zone6 from './zone6.png';
import zone7 from './zone7.png';
import zone8 from './zone8.png';
import zone9 from './zone9.png';
import zone10 from './zone10.png';
import zone11 from './zone11.png';
import zone12 from './zone12.png';
import zone13 from './zone13.png';
import zone14 from './zone14.png';
import zone15 from './zone15.png';
import zone16 from './zone16.png';
import zone17 from './zone17.png';
import zone18 from './zone18.png';
import zone19 from './zone19.png';
import zone20 from './zone20.png';
import zone21 from './zone21.png';
import zone22 from './zone22.png';
import zone23 from './zone23.png';
import zone24 from './zone24.png';

const allCards = [
  { id: 1, text: 'Prueba A/B', points: 1, background: carta1 },
  { id: 2, text: 'Análisis Conjunto', points: 1, background: carta2 },
  { id: 3, text: 'Carta de Intención', points: 1, background: carta3 },
  { id: 4, text: 'Medidor de Sensibilidad de Precios', points: 1, background: carta4 },
  { id: 5, text: 'Mago de Oz', points: 1, background: carta5 },
  { id: 6, text: 'Pop Up Store', points: 1, background: carta6 },
  { id: 7, text: 'Video Explicativo', points: 1, background: carta7 },
  { id: 8, text: 'Encuesta', points: 1, background: carta8 },
  { id: 9, text: 'Página de Destino (Landing Page)', points: 1, background: carta9 },
  { id: 10, text: 'Calculadora de Precios', points: 1, background: carta10 },
  { id: 11, text: 'Hacer un pedido', points: 1, background: carta11 },
  { id: 12, text: 'Publicidad Online', points: 1, background: carta12 },
  { id: 13, text: 'Poco a Poco', points: 1, background: carta13 },
  { id: 14, text: 'Prototipo', points: 1, background: carta14 },
  { id: 15, text: 'Evento', points: 1, background: carta15 },
  { id: 16, text: 'Volantes', points: 1, background: carta16 },
  { id: 17, text: 'Entrada en el blog', points: 1, background: carta17 },
  { id: 18, text: 'Investigación Contextual', points: 1, background: carta18 },
  { id: 19, text: 'Picnic en el cementerio', points: 1, background: carta19 },
  { id: 20, text: 'Prototipo de juguete', points: 1, background: carta20 },
  { id: 21, text: 'Entrevista problema - solución', points: 1, background: carta21 },
  { id: 22, text: 'Prueba de conserje', points: 1, background: carta22 },
//  { id: 23, text: 'Pruebas de funcionalidad', points: 1, background: carta23 },
//  { id: 24, text: 'Pruebas de rendimiento', points: 1, background: carta24 },
//  { id: 25, text: 'Pruebas de usabilidad', points: 1, background: carta25 },
//  { id: 26, text: 'Pruebas de compatibilidad', points: 1, background: carta26 },
//  { id: 27, text: 'Pruebas de accesibilidad', points: 1, background: carta27 },
];

const dropzoneMappings = [
  { name: 'Plataformas de Análisis de Datos', validCards: [2, 18, 1], background: zone1 },
  { name: 'Asistencia en Tiempo Real y Chatbots', validCards: [23, 26, 27], background: zone2 },
  { name: 'Asistencia Omnicanal', validCards: [23, 24, 25, 26, 1, 2, 3, 4], background: zone3 },
  { name: 'Portal de Servicios', validCards: [9, 17, 3], background: zone4 },
  { name: 'Predicción de Mercado', validCards: [4, 10], background: zone5 },
  { name: 'Optimización de Precios', validCards: [4, 10], background: zone6 },
  { name: 'Web Content', validCards: [9, 17], background: zone7 },
  { name: 'Marketing and Sales Data', validCards: [4, 10], background: zone8 },
  { name: 'Customer Satisfaction and Trust', validCards: [8], background: zone9 },
  { name: 'Customer Acquisition', validCards: [18], background: zone10 },
  { name: 'Cost Optimization', validCards: [4, 10], background: zone11 },
  { name: 'Benchmark', validCards: [1, 19], background: zone12 },
  { name: 'Algoritmos Personalizados', validCards: [23, 26, 27], background: zone13 },
  { name: 'Augmented Reality', validCards: [1, 7], background: zone14 },
  { name: 'Blockchain for Retail Supply Chain', validCards: [1], background: zone15 },
  { name: 'Digital Platform', validCards: [9, 12, 17], background: zone16 },
  { name: 'Click&Collect', validCards: [11], background: zone17 },
  { name: 'TV Advertising', validCards: [12], background: zone18 },
  { name: 'People Network Base Solutions', validCards: [8, 21], background: zone19 },
  { name: 'Platform Channels', validCards: [9, 12, 17], background: zone20 },
  { name: 'Microsegmentos', validCards: [18], background: zone21 },
  { name: 'Data Monetization', validCards: [4, 10], background: zone22 },
  { name: 'Data Management', validCards: [1, 14, 19], background: zone23 },
  { name: 'Ubiquitous Access', validCards: [3, 12], background: zone24 },
];

const getRandomZones = (zones, count) => {
  const shuffled = [...zones].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};

const Card = ({ id, background }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'CARD',
    item: { id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }), [id]);

  return (
    <div
      ref={drag}
      style={{
        width: '100px',
        height: '150px',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        borderRadius: '8px',
        opacity: isDragging ? 0.5 : 1,
        cursor: 'grab',
        margin: '5px',
      }}
    />
  );
};

const DropZone = ({ name, background, onDrop, children, onClick, isLocked }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'CARD',
    drop: (item) => {
      if (!children && !isLocked) {
        onDrop(item, name);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }), [name, children, isLocked]);

  return (
    <div
      ref={drop}
      style={{
        width: '150px',
        height: '200px',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        borderRadius: '8px',
        margin: '10px',
        border: isOver ? '2px solid green' : '2px dashed #ccc',
        position: 'relative',
      }}
      onClick={!isLocked ? onClick : undefined}
    >
      {children && (
        <div
          style={{
            width: '80px',
            height: '120px',
            position: 'absolute',
            top: '40px',
            left: '35px',
            backgroundImage: `url(${children.background})`,
            backgroundSize: 'cover',
          }}
        />
      )}
    </div>
  );
};

const SprintTest = () => {
  const [images, setImages] = useState(allCards);
  const [activeZones, setActiveZones] = useState(getRandomZones(dropzoneMappings, 5));
  const [droppedImages, setDroppedImages] = useState({});
  const [scores, setScores] = useState({});
  const [results, setResults] = useState(null);

  useEffect(() => {
    const savedResults = localStorage.getItem('sprintTestResults');
    if (savedResults) {
      setResults(JSON.parse(savedResults));
    }
  }, []);

  const handleDrop = (item, zoneName) => {
    if (!droppedImages[zoneName] && !results) {
      const droppedCard = images.find((img) => img.id === item.id);
      setDroppedImages((prev) => ({
        ...prev,
        [zoneName]: droppedCard,
      }));
      setImages((prev) => prev.filter((img) => img.id !== item.id));
    }
  };

  const handleRemove = (zoneName) => {
    if (droppedImages[zoneName] && !results) {
      const removedCard = droppedImages[zoneName];
      setDroppedImages((prev) => {
        const updated = { ...prev };
        delete updated[zoneName];
        return updated;
      });
      setImages((prev) => [...prev, removedCard]);
    }
  };

  const submitTest = () => {
    const newScores = {};
    activeZones.forEach((zone) => {
      const card = droppedImages[zone.name];
      newScores[zone.name] = zone.validCards.includes(card?.id) ? card.points : 0;
    });
    setScores(newScores);
    setResults(newScores);
    localStorage.setItem('sprintTestResults', JSON.stringify(newScores));
  };

  const allZonesFilled = activeZones.every((zone) => droppedImages[zone.name]);

  const totalScore = results ? Object.values(results).reduce((acc, score) => acc + score, 0) : 0;

  return (
    <DndProvider backend={HTML5Backend}>
      <div style={{ padding: '20px' }}>
        <h1>Sprint Test</h1>
        {!results && (
          <button
            onClick={submitTest}
            disabled={!allZonesFilled}
            style={{
              marginTop: '20px',
              padding: '10px 20px',
              fontSize: '16px',
            }}
          >
            Enviar Jugada
          </button>
        )}
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
          {activeZones.map((zone, idx) => (
            <DropZone
              key={idx}
              name={zone.name}
              background={zone.background}
              onDrop={handleDrop}
              onClick={() => handleRemove(zone.name)}
              children={droppedImages[zone.name]}
              isLocked={!!results}
            />
          ))}
        </div>
        {!results && (
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {images.map((image) => (
              <Card key={image.id} {...image} />
            ))}
          </div>
        )}
        {results && (
          <div style={{ marginTop: '20px' }}>
            <h2>Puntaje Total: {totalScore}</h2>
            <ul>
              {Object.entries(results).map(([zone, score]) => (
                <li key={zone}>
                  {zone}: {score} punto(s)
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </DndProvider>
  );
};

export default SprintTest;
