import React, { useState, useEffect } from "react";

const ImageGallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [language, setLanguage] = useState("es");
  const [sprint, setSprint] = useState("1");
  const [images, setImages] = useState([]);
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
  const [zoomVisible, setZoomVisible] = useState(false);

  useEffect(() => {
    const generateImages = () => {
      const totalImages = 46; // Número de imágenes por idioma y sprint
      const generatedImages = [];
      for (let i = 1; i <= totalImages; i++) {
        generatedImages.push({
          front: `/${language}/${sprint}/${i}.png`,
          back: `/${language}/${sprint}/${i}_REV.png`,
        });
      }
      setImages(generatedImages);
    };

    generateImages();
  }, [language, sprint]);

  const openModal = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsFlipped(false);
    setZoomVisible(false);
  };

  const toggleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  const changeLanguage = (lang) => {
    setLanguage(lang);
  };

  const changeSprint = (sprintValue) => {
    setSprint(sprintValue);
  };

  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100; // Posición X como porcentaje
    const y = ((e.clientY - rect.top) / rect.height) * 100; // Posición Y como porcentaje
    setZoomPosition({ x, y });
  };

  const flagStyle = (isActive) => ({
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    cursor: "pointer",
    boxShadow: isActive ? "0 0 5px 3px #007bff" : "0 4px 8px rgba(0, 0, 0, 0.2)",
    transform: isActive ? "scale(1.1)" : "scale(1)",
    transition: "transform 0.3s, box-shadow 0.3s",
    margin: "0 8px",
  });

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {/* Banderas de idioma en la parte superior derecha */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: "10px 20px",
        }}
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Spain.svg"
          alt="Spanish"
          style={flagStyle(language === "es")}
          onClick={() => changeLanguage("es")}
        />
        <img
          src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
          alt="English"
          style={flagStyle(language === "en")}
          onClick={() => changeLanguage("en")}
        />
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/0/05/Flag_of_Brazil.svg"
          alt="Portuguese"
          style={flagStyle(language === "pt")}
          onClick={() => changeLanguage("pt")}
        />
      </div>

      {/* Botones de selección de sprint */}
      <div style={{ display: "flex", gap: "8px", justifyContent: "center" }}>
        <button onClick={() => changeSprint("1")} style={{ padding: "8px", cursor: "pointer" }}>
          Sprint Sustentabilidad
        </button>
      </div>

      {/* Galería de imágenes */}
      <div style={{ display: "flex", gap: "16px", justifyContent: "center", flexWrap: "wrap" }}>
        {images.map((image, index) => (
          <div
            key={index}
            onClick={() => openModal(index)}
            style={{
              width: "300px",
              height: "400px",
              overflow: "hidden",
              cursor: "pointer",
            }}
          >
            <img
              src={image.front}
              alt={`Image ${index}`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </div>
        ))}
      </div>

      {/* Modal de imagen */}
      {isOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 50,
          }}
        >
          <div
            style={{
              position: "relative",
              width: "75%",
              maxWidth: "450px",
              padding: "16px",
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
              <button
                onClick={toggleFlip}
                style={{
                  backgroundColor: "#3b82f6",
                  padding: "8px 16px",
                  color: "#fff",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                {isFlipped ? "Ver Frente" : "Ver Reverso"}
              </button>
              <button
                onClick={closeModal}
                style={{
                  backgroundColor: "#ef4444",
                  padding: "8px 16px",
                  color: "#fff",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Cerrar
              </button>
            </div>

            <div
              style={{
                position: "relative",
                overflow: "hidden",
                cursor: "none",
              }}
              onMouseMove={handleMouseMove}
              onMouseEnter={() => setZoomVisible(true)}
              onMouseLeave={() => setZoomVisible(false)}
            >
              <img
                src={isFlipped ? images[currentIndex].back : images[currentIndex].front}
                alt={`Image ${currentIndex}`}
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px",
                }}
              />
              {zoomVisible && (
                <div
                  style={{
                    position: "absolute",
                    width: "150px",
                    height: "150px",
                    border: "2px solid #007bff",
                    borderRadius: "50%",
                    backgroundImage: `url(${isFlipped ? images[currentIndex].back : images[currentIndex].front})`,
                    backgroundSize: "500%", // Incrementa este valor para un mayor zoom
                    backgroundPosition: `${zoomPosition.x}% ${zoomPosition.y}%`,
                    pointerEvents: "none",
                    transform: "translate(-50%, -50%)",
                    top: `${zoomPosition.y}%`,
                    left: `${zoomPosition.x}%`,
                  }}
                ></div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
