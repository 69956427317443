import { Row, Col, Card, CardBody } from "reactstrap";
import img from "../assets/img/fondo3.png";
import Galeria from "../components/Cartas/galeria";

const galeria = () => {

    return (
        <div className="content"
            style={{
                backgroundImage: `url(${img})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '100vh',
                width: '100vw',
            }}>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#003366' }}>
                        <CardBody>
                            <h1>Cartas en juego</h1>
                            <Galeria />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default galeria;